
import datatable from "@/components/datatable/index.vue"

import {mapGetters} from 'vuex'

export default {
	name: 'Societe',
	data() {
		return {
			table_headers: ''
		};
	},
	components:{
		datatable
	},
	methods: {
		delete_user(){
			
		},
		edit_user(){
			this.$router.push({name: 'EditUser',params:{id:1}})
		},
		view_user(){
			this.$router.push({name: 'ShowUser',params:{id:1}})
		},
	},
	computed: mapGetters({societes:"settingStoreIndex/SOCIETE"}),
	created(){
		this.$store.dispatch("settingStoreIndex/SOCIETE")
	},
	watch:{
		societes(){
			if(this.societes.length>0)
				this.table_headers = Object.keys(this.societes[0])
		}
	}
}